import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";
import { TbArrowsSort } from "react-icons/tb";
import { Loader } from "../global/Loader";

const Pricing = () => {
  const [starterOpen, setStarterOpen] = useState(false);
  const [monthlyOpen, setMonthlyOpen] = useState(false);
  const [annualOpen, setAnnualOpen] = useState(false);

  const { pricing } = useAuth();

  const toggleFeatures = (section) => {
    if (document.getElementById(section).style.gridTemplateRows === "1fr") {
      document.getElementById(section).style.gridTemplateRows = "0fr";
    } else {
      document.getElementById(section).style.gridTemplateRows = "1fr";
    }
    switch (section) {
      case "shoebox-starter":
        setStarterOpen(!starterOpen);
        break;
      case "shoebox-pro":
        setMonthlyOpen(!monthlyOpen);
        break;
      case "shoebox-pro-annual":
        setAnnualOpen(!annualOpen);
        break;
      default:
        return;
    }
  };

  const tileOrder = (a, b) => {
    if (a.details.price < b.details.price) {
      return -1;
    }
    if (a.details.price > b.details.price) {
      return 1;
    }
    return 0;
  };

  const mobileTileOrder = (a, b) => {
    if (a.details.mobileOrder < b.details.mobileOrder) {
      return -1;
    }
    if (a.details.mobileOrder > b.details.mobileOrder) {
      return 1;
    }
    return 0;
  };

  const PlanFeatures = (item) => {
    let features = [];
    if (item.item.details.features) {
      features = item.item.details.features;
    }
    return (
      <>
        {features.length > 0
          ? features?.map((feature, index) => {
              return (
                <div className="plan-feature-item" key={index}>
                  {feature.item === "expense-limit" ? (
                    <div className="feature-text">{feature.image}</div>
                  ) : (
                    <img
                      alt="pricing tier icon"
                      src={feature.image}
                      className="feature-graphic"
                    />
                  )}
                  {feature.text}
                </div>
              );
            })
          : null}
      </>
    );
  };

  const [isDesktop, setIsDesktop] = useState(true);
  const [isPhone, setIsPhone] = useState(true);

  let tierPanel;

  useEffect(() => {
    updateWidth();
  }, []);

  const updateWidth = () => {
    setIsDesktop(window.innerWidth > 800);
    if (
      window.innerWidth > 800 &&
      document.getElementById("shoebox-starter") &&
      document.getElementById("shoebox-pro") &&
      document.getElementById("shoebox-pro-annual")
    ) {
      document.getElementById("shoebox-starter").style.gridAutoRows = "1fr";
      document.getElementById("shoebox-pro").style.gridAutoRows = "1fr";
      document.getElementById("shoebox-pro-annual").style.gridAutoRows = "1fr";
    }
  };

  window.addEventListener("resize", updateWidth);

  if (isDesktop) {
    pricing?.sort(tileOrder);
  } else {
    pricing?.sort(mobileTileOrder);
  }

  return (
    <section className="pricing-tiers">
      {pricing?.map((item) => {
        let tier = item.details.title.toLowerCase().split(" ").join("-");

        return (
          <div key={item.details.shortTitle} className="price-block">
            <img
              alt="pricing tier icon"
              src={item.details.graphic}
              className="tier-graphic"
            />
            <div className="price-header">
              <span className="price">{"$" + item.details.price}</span>
              <span className="term">{item.details.term}</span>
            </div>
            <div
              id={item.details.title.toLowerCase().split(" ").join("-")}
              className="all-features-expansion-wrapper"
            >
              <div className="all-features-expansion-content">
                <PlanFeatures item={item} />
              </div>
            </div>
            {!isDesktop ? (
              tier.includes("pro") ? (
                tier.includes("pro-annual") ? (
                  <span
                    className="tile-toggle"
                    id={tier}
                    onClick={() => toggleFeatures(tier)}
                  >
                    {annualOpen ? "hide features" : "see all features"}
                  </span>
                ) : (
                  <span
                    className="tile-toggle"
                    id={tier}
                    onClick={() => toggleFeatures(tier)}
                  >
                    {monthlyOpen ? "hide features" : "see all features"}
                  </span>
                )
              ) : (
                <span
                  className="tile-toggle"
                  id={tier}
                  onClick={() => toggleFeatures(tier)}
                >
                  {starterOpen ? "hide features" : "see all features"}
                </span>
              )
            ) : null}
            {/* Turning off until live */}
            {/* <div className='tier-select-button'> */}
            {/* <sl-button>{item.details.button}</sl-button> */}
            {/* </div> */}
          </div>
        );
      })}
    </section>
  );
};

export { Pricing };
